import React from 'react';

const FooterLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="16"
    viewBox="0 0 48 16"
    fill="none"
  >
    <path
      d="M0.492676 15.2997L3.45069 15.2997C1.64515 12.058 0.749766 8.30218 0.749766 2.86791C0.749766 2.86791 1.10437 2.61082 1.89929 4.13266C2.31299 4.93051 3.12268 6.62373 4.49088 9.2271C6.24027 12.5545 8.13151 15.4415 9.88091 15.4415C10.0789 15.4415 10.3626 15.3854 10.5192 15.2997L10.5192 1.07717L7.56118 1.07717C9.36968 4.31882 10.2651 8.07465 10.2651 13.3937C10.2651 13.3937 10.008 13.6921 9.23966 12.8677C8.62796 12.1998 7.69121 10.8199 6.26687 8.18694C3.59254 3.23729 3.2527 0.932373 1.13392 0.932373C0.932981 0.932373 0.649295 0.991474 0.492676 1.07717L0.492676 15.2997Z"
      fill="#050505"
    />
    <path
      d="M17.702 15.4415C21.0738 15.4415 23.3196 12.5397 23.3196 8.18694C23.3196 3.8342 21.0738 0.932373 17.702 0.932373C14.3155 0.932373 12.0845 3.8342 12.0845 8.18694C12.0845 12.5397 14.3155 15.4415 17.702 15.4415ZM14.3008 8.17216C14.3008 3.94944 15.6542 1.13331 17.6873 1.13331C19.7351 1.13331 21.1004 3.94944 21.1004 8.17216C21.1004 12.4126 19.7351 15.2287 17.6873 15.2287C15.6542 15.2287 14.3008 12.4126 14.3008 8.17216Z"
      fill="#050505"
    />
    <path
      d="M24.1675 1.07764C25.648 5.92682 26.502 8.79909 27.9529 13.536C28.2514 14.517 28.8187 15.442 29.8589 15.442C30.8962 15.442 31.4665 14.517 31.7649 13.536C33.2011 8.79909 34.0817 5.92682 35.5474 1.07764L33.3725 1.07764C31.9068 5.92682 31.0823 8.81387 29.5871 13.536C29.46 13.9349 29.2738 14.062 28.9606 14.062C27.0841 14.062 26.8714 2.74131 27.2703 1.07764L24.1675 1.07764Z"
      fill="#050505"
    />
    <path
      d="M39.2156 15.2997C39.059 14.6466 38.9585 12.4983 39.0294 10.1372L43.7812 10.1372C44.2363 11.7005 44.7179 13.3375 45.3178 15.2997L47.4927 15.2997C46.027 10.4505 45.1464 7.57525 43.7103 2.83836C43.4118 1.85729 42.8415 0.932373 41.8042 0.932373C40.7641 0.932373 40.1967 1.85729 39.8982 2.83836C38.4591 7.57525 37.5933 10.4505 36.1128 15.2997L39.2156 15.2997ZM39.0442 9.92449C39.1713 6.32528 39.6825 2.31237 40.965 2.31237C41.2782 2.31237 41.4319 2.52808 41.5324 2.83836C42.4012 5.57175 43.0395 7.6905 43.7103 9.92449L39.0442 9.92449Z"
      fill="#050505"
    />
  </svg>
);

export default FooterLogo;
