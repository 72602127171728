import React, { FC } from 'react';
import FacebookIcon from './FacebookIcon';
import InstagramIcon from './InstagramIcon';
import styled from 'styled-components';
import FooterLogo from './FooterLogo';
import { useRouter } from 'apis/history';

interface FooterProps {
  isMobileApp: boolean;
}

const Footer: FC<FooterProps> = ({ isMobileApp }) => {
  const {
    goToLanding,
    goToContact,
    goToReviews,
    goToTerms,
    goToTermsMobile,
    goToPrivacy,
    goToPrivacyMobile,
  } = useRouter();

  return (
    <ReviewsFooter>
      <SiteMap>
        <Logo />
        <StyledList>
          {!isMobileApp ? (
            <>
              <LinkWrapper>
                <StyledText onClick={() => goToLanding()}>Home</StyledText>
              </LinkWrapper>
              <LinkWrapper>
                <StyledText onClick={() => goToReviews()}>Reviews</StyledText>
              </LinkWrapper>
              {/* <LinkWrapper>
                <StyledText>
                  <LinkStyled href="https://help.nova.health/hc/en-us">
                    Help Center
                    </LinkStyled>
                </StyledText>
              </LinkWrapper> */}
              <LinkWrapper>
                <StyledText onClick={() => goToContact()}>
                  Contact Us
                </StyledText>
              </LinkWrapper>
              <LinkWrapper>
                <StyledText onClick={() => goToTerms()}>
                  Terms & Conditions
                </StyledText>
              </LinkWrapper>
              <LinkWrapper>
                <StyledText onClick={() => goToPrivacy()}>
                  Privacy Policy
                </StyledText>
              </LinkWrapper>
            </>
          ) : (
            <>
              <LinkWrapper>
                <StyledText onClick={() => goToTermsMobile()}>
                  Terms & Conditions
                </StyledText>
              </LinkWrapper>
              <LinkWrapper>
                <StyledText onClick={() => goToPrivacyMobile()}>
                  Privacy Policy
                </StyledText>
              </LinkWrapper>
            </>
          )}
        </StyledList>
      </SiteMap>
      <CopyrightContainer>
        {!isMobileApp && (
          <>
            <SocialsContainer>
              <FacebookIcon />
              <InstagramIcon />
            </SocialsContainer>
          </>
        )}
        <span>&copy; 2024 Nova. All rights reserved.</span>
      </CopyrightContainer>
    </ReviewsFooter>
  );
};

export default Footer;

const ReviewsFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fef3f3 !important;
  padding: 24px 0;

  @media (max-width: 610px) {
    padding: 24px 16px;
  }
`;

const Logo = styled(FooterLogo)`
  height: 15px !important;
`;

const SocialsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
`;

const SiteMap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 12px;
  gap: 32px;
  min-height: 48px;
  width: 100%;
  border-bottom: 1px solid #e6e5e2;

  @media (max-width: 610px) {
    gap: 16px;
  }

  @media (max-width: 610px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 16px;
  }
`;

const LinkWrapper = styled.li`
  cursor: pointer;
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-top: 3px;
`;

const StyledText = styled.p`
  color: #555770 !important;
  font-size: 14px;
  text-decoration: none;
  font-weight: 400 !important;
  letter-spacing: -0.336px;
  font-family: Mulish;

  @media (max-width: 720px) {
    font-size: 12px !important;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledList = styled.ul`
  display: flex;
  gap: 32px;
  align-items: center;

  @media (max-width: 610px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
  }
`;

const LinkStyled = styled.a`
  color: #555770 !important;
  font-size: 14px !important;
  text-decoration: none;
  font-weight: 400 !important;
  letter-spacing: -0.336px;
  line-height: 140%;
  font-family: Mulish, sans-serif;

  @media (max-width: 710px) {
    font-size: 12px !important;
  }
`;

const CopyrightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
  gap: 8px;

  svg {
    height: 32px;
    display: flex;
    align-items: center;
  }

  span {
    color: #555770 !important;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    font-family: Mulish, sans-serif;
  }

  @media (max-width: 710px) {
    flex-direction: column;
    align-items: center;

    span {
      font-size: 12px !important;
    }
  }

  @media (max-width: 610px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    margin-top: 16px;

    span {
      font-size: 12px;
    }
  }
`;
